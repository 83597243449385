<template>
  <div v-if="productData">
    <div class="banner">
      <img :src="productData.detailImg" alt="" />
      <div class="typeList">
        <p
          v-for="(item, index) in productData.plans"
          :key="index"
          :class="activeType == index ? 'active' : 'no-active'"
          @click="changeType(index, item)"
        >
          <span class="check"></span>
          <span class="price">￥{{ item.planPremium }}</span>
          <span class="name">{{ item.securityPlanName }}</span>
        </p>
      </div>
    </div>
    <div class="ensureDetail">
      <div class="inviolable-rights-title">
        <div class="left">保障责任</div>
        <div class="right" @click="rightsAndInterests">
          <p>查看详情</p>
          <img src="../assets/right-icon.png" alt="" />
        </div>
      </div>
      <div class="inviolable-rights-msg">
        <div class="item" v-for="(item, index) in plansData" :key="index">
          <p class="name">{{ item.benefitName }}</p>
          <p class="price">{{ item.insuredAmountShow }}</p>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="claim-process">
      <img src="../assets/bg-banner1.jpeg" alt="" />
      <img src="../assets/bg-banner2.jpeg" alt="" />
      <div class="footer">
        <div class="price">
          <p>价格：¥</p>
          <p class="num">{{ price }}</p>
          <p>元/起</p>
        </div>
        <div class="submit" @click="submit">立即投保</div>
      </div>
      <!-- 回溯须知 -->
      <div class="firstBottomPopup">
        <van-popup
          v-model="recallPopup"
          :close-on-click-overlay="false"
          position="bottom"
        >
          <div class="notify">
            <div class="title">投保说明</div>
            <div class="msg">
              <p>
                即将进入投保流程，请仔细阅读<span @click="customerNotification"
                  >客户告知书</span
                >等，为了保障您的权益，您的投保轨迹将被记录井加密保存。
              </p>
            </div>
            <div class="tips">
              <p>
                本产品由中国人民财产保险股份有限公司承保，由乐荐保保险代理有限公司提供销售和协助理赔等保险服务。
              </p>
            </div>
            <div class="btn" @click="okBtn">我知道了</div>
          </div>
        </van-popup>
      </div>

      <!-- 健康提示 -->
      <div class="centerPopup">
        <van-popup v-model="healthShow" :close-on-click-overlay="false">
          <div class="health">
            <p class="title">健康提示</p>
            <p class="tip">很遗憾，您的条件不符合参保条件</p>
            <p class="btn" @click="healthShow = false">我知道了</p>
          </div>
        </van-popup>
      </div>
    </div>
  </div>
</template>

<script>
import { productDetail, productBenefitDetail, getOpenId } from "../utils/api";
export default {
  components: {},
  data() {
    return {
      activeType: 2,
      tabList: [
        {
          title: "商品详情",
        },
        {
          title: "产品特色",
        },
        {
          title: "投保须知",
        },
        {
          title: "理赔指引",
        },
        {
          title: "典型案例",
        },
      ],
      tabActive: 0,
      productData: null,
      plansId: "",
      plansData: [],
      recallPopup: true,
      isFirstEnter: false,
      informPopup: false,
      isInform: true,
      healthShow: false,
      plansCode: "", //选中计划的code
      price: "300", //产品价格
      productId: "1517427890227630082", // 生产环境产品id
    };
  },
  created() {
    //判断是否需要重新获取回溯id
    if (!sessionStorage.getItem("know")) {
      this.recallPopup = true;
    } else {
      this.recallPopup = false;
    }
  },
  async mounted() {
    if (this.$route.query.appId) {
      localStorage.setItem("appId", this.$route.query.appId);
    } else {
      localStorage.removeItem("appId");
    }
    sessionStorage.setItem("productId", this.productId);
    if (this.$route.query.code) {
      let params = {
        code: this.$route.query.code,
        channelId: localStorage.getItem("appId") || "wx2f80f772533e490f",
      };
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      await getOpenId(params).then((res) => {
        if (res.success) {
          sessionStorage.setItem("orderNo", this.$route.query.orderNo);
          sessionStorage.setItem("openId", res.result.openId);
        } else {
          this.$router.replace({
            path: "/confirmOrder",
          });
        }
      });
    }
    if (
      sessionStorage.getItem("openId") == null ||
      sessionStorage.getItem("openId") == "null"
    ) {
      this.wxLoad();
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name == "rightsAndInterests") {
      to.meta.isBack = true;
    }
    next();
  },
  async activated() {
    if (!this.$route.meta.isBack || this.isFirstEnter) {
      if (!sessionStorage.getItem("know")) {
        this.recallPopup = true;
      } else {
        this.recallPopup = false;
      }
      this.isFirstEnter = false;
    }
    this.$toast.loading({
      message: "加载中...",
      forbidClick: true,
      duration: 0,
    });

    await this.productDetail();
    await this.productBenefitDetail();
    this.$toast.clear();
    this.$route.meta.isBack = false;
    this.isFirstEnter = false;
  },
  methods: {
    // 静默授权
    wxLoad() {
      let appId = this.$route.query.appId || "wx2f80f772533e490f";
      let local = window.location.href;
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        appId +
        "&redirect_uri=" +
        encodeURIComponent(local) +
        "&response_type=code&scope=snsapi_base&state=1#wechat_redirect";
    },
    //客户告知书
    customerNotification() {
      this.$router.push({
        path: "/pdf",
        query: {
          type: "native",
        },
      });
    },
    //跳转产品资料
    material() {
      this.$router.push({
        path: "/material",
        query: {
          id: this.$route.query.id,
        },
      });
    },
    //阅读并投保
    next() {
      this.informPopup = false;
      this.$router.push({
        path: "/insurePackage",
        query: {
          id: this.$route.query.id,
        },
      });
    },
    //立即投保
    submit() {
      //跳转投保须知
      this.$router.push({
        path: "/insuranceNotice",
        query: {
          channel: "technologicalProcess",
        },
      });
    },
    //权益-查看详情
    rightsAndInterests() {
      var price;
      if (this.plansCode == "ZZJ689") {
        price = "150万";
      } else {
        price = "300万";
      }
      this.$router.push({
        path: "/rightsAndInterests",
        query: {
          id: this.plansId,
          price: price,
        },
      });
    },
    //我知道了
    okBtn() {
      this.recallPopup = false;
      sessionStorage.setItem("know", true); //存储用户是否知晓该提示
    },

    //权益数据
    async productBenefitDetail() {
      let params = {
        plansId: this.plansId,
      };
      await productBenefitDetail(params).then((res) => {
        if (res.success) {
          let obj = {
            benefitName: "保险期间",
            insuredAmountShow: "1-6年",
          };
          res.result.push(obj);
          this.plansData = res.result;
        }
      });
    },
    //产品详情
    async productDetail() {
      let params = {
        productId: this.productId,
      };
      await productDetail(params).then((res) => {
        if (res.success) {
          this.productData = res.result;
          sessionStorage.setItem("planData", JSON.stringify(res.result.plans));
          this.productData.plans.map((item, index) => {
            if (this.activeType == index) {
              this.plansId = item.id;
              this.plansCode = item.code;
              this.price = item.planPremium;
            }
          });
          this.tabList[0]["img"] = this.productData.detail;
          this.tabList[1]["img"] = this.productData.characteristic;
          this.tabList[2]["img"] = this.productData.notice;
          this.tabList[3]["img"] = this.productData.claimsGuidelines;
          this.tabList[4]["img"] = this.productData.classicCases;
        }
      });
    },
    //切换type
    async changeType(index, item) {
      this.activeType = index;
      this.plansId = item.id;
      this.plansCode = item.code;
      this.price = item.planPremium;
      await this.productBenefitDetail();
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  background: #f2f2f2;
  position: relative;
  img {
    width: 100%;
  }
}
.detail {
  padding: 0 10px;
  .name {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 22px;
    margin-top: 13px;
  }
  .introduction {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    text-align: justify;
    margin-top: 5px;
  }
  .from {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    margin-top: 12px;
  }
  .distribution-company {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    margin-top: 4px;
  }
}
.line {
  width: 100%;
  height: 10px;
  background: #f7f7f7;
  margin-top: 20px;
  // background: rgb243, 89, 54;
}
.typeList {
  position: absolute;
  bottom: 10px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 0 10px;
  p {
    height: 45px;
    border-radius: 6px;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    text-align: center;
    padding: 0 20px;
    span {
      display: block;
    }
    .price {
      font-size: 18px;
      margin-top: 3px;
    }
    .name {
      font-size: 12px;
    }
  }
  .active {
    background: rgb(255, 116, 24);
    color: #fff;
    position: relative;
    .price,
    .name {
      color: #ffffff;
    }
    .check {
      display: inline-block;
      width: 25px;
      height: 25px;
      background: url("../assets/check.svg");
      background-size: 100% 100%;
      position: absolute;
      bottom: -1px;
      right: 0;
      box-sizing: border-box;
    }
  }
  .no-active {
    // border: 1px solid $primary-color;
    // color: $primary-color;
    background: #fff;
    .price {
      color: #333333;
    }
    .name {
      color: #999999;
    }
    .check {
      display: none;
    }
  }
}
.commission {
  padding: 0 15px;
  margin-top: 16px;
  .msg {
    width: 100%;
    background: url("../assets/commission-bg.png") no-repeat;
    background-size: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    .left {
      display: flex;
      align-items: center;
      p {
        font-size: 14px;
        font-weight: bold;
        color: #e06c49;
        line-height: 20px;
      }
      img {
        width: 14px;
        margin-left: 8px;
      }
    }
    .share {
      img {
        width: 54px;
      }
    }
  }
}
.ensureDetail {
  padding: 0 10px;
  .inviolable-rights-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    .left {
      font-size: 15px;
      font-weight: bold;
      color: #333333;
      line-height: 21px;
    }
    .right {
      display: flex;
      align-items: center;
      p {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
      }
      img {
        width: 12px;
        margin-left: 3px;
      }
    }
  }
  .inviolable-rights-msg {
    // background: $bg7;
    border-radius: 4px;
    // padding: 0 10px 10px;
    margin-top: 8px;

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      // .name {
      //   font-size: 16px;
      //   font-weight: 400;
      //   color: #333333;
      //   line-height: 16px;
      // }
      // .msg {
      //   padding: 10px 12px;
      //   background: $bg7;
      //   margin-top: 12px;
      //   border-radius: 4px;
      //   p {
      //     font-size: 14px;
      //     font-family: PingFangSC-Regular, PingFang SC;
      //     font-weight: 400;
      //     color: #333333;
      //     line-height: 24px;
      //   }
      // }
      p {
        font-size: 15px;
        font-weight: 400;
        color: #333;
        line-height: 21px;
      }
    }
  }
}
.guaranteed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  margin-top: 12px;
  p {
    font-size: 15px;
    font-weight: 400;
    color: #333333;
    line-height: 21px;
  }
}
.msgImg {
  width: 100%;
  padding: 0 10px;
  /deep/ img {
    width: 100% !important;
  }
}
.claim-process {
  // padding: 20px 10px 10px;
  img {
    width: 100%;
    height: 100%;
  }
  .tit {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 22px;
  }
  .step {
    margin-top: 17px;
    .name {
      display: flex;
      align-items: center;
      img {
        width: 15px;
      }
      p {
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
        margin-left: 6px;
      }
    }
    .msg {
      margin-top: 7px;
      display: flex;
      .left {
        width: 15px;
        // height: 68px;
        display: flex;
        justify-content: center;
        p {
          width: 1px;
          // height: 68px;
          border-left: 1px dashed #ffbfac;
        }
      }
      .right {
        flex: 1;
        width: 100%;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
        text-align: justify;
        margin-left: 6px;
        margin-bottom: 15px;
      }
    }
  }
}
.distribution {
  padding: 12px 10px 80px;
  background: $bg7;
  font-size: 12px;
  font-weight: 400;
  color: #9c9c9c;
  line-height: 17px;
}
// .productImg {
//   padding-bottom: 75px;
// }
.notify {
  padding: 28px 25px 14px;
  .title {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 17px;
  }
  .msg {
    margin-top: 12px;
    p {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      text-align: justify;
    }
    span {
      color: #569cff;
    }
  }
  .tips {
    margin-top: 20px;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 17px;
    text-align: justify;
  }
  .btn {
    width: 307px;
    height: 45px;
    margin: 0 auto;
    background: $primary-color;
    border-radius: 26px;
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
  }
}
.inform {
  height: 100%;
  padding: 20px;
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  .title {
    position: relative;
    background: #fff;

    p {
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
      text-align: center;
    }
    img {
      position: absolute;
      top: 4px;
      right: 0;
      width: 14px;
    }
  }
  .detail {
    margin-top: 20px;
    font-size: 15px;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    text-align: justify;
    padding: 0;
    // height: 90%;
    overflow-y: auto;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 159px;
      height: 45px;
      border-radius: 23px;
      font-size: 15px;
      font-weight: 500;
      line-height: 21px;
    }
    p:first-child {
      border: 1px solid $primary-color;
      color: $primary-color;
    }
    p:last-child {
      background: $primary-color;
      border: 1px solid $primary-color;
      color: #fff;
    }
  }
  .next {
    p {
      height: 45px;
      border-radius: 23px;
      font-size: 15px;
      font-weight: 500;
      line-height: 21px;
      width: 234px;
      background: $primary-color;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      margin-top: 20px;
    }
  }
}
.health {
  width: 320px;
  height: 160px;
  background: #ffffff;
  border-radius: 8px;
  p {
    text-align: center;
  }
  .title {
    font-size: 17px;
    font-weight: 500;
    color: #333;
    line-height: 24px;
    margin-top: 32px;
    margin-bottom: 24px;
  }
  .tip {
    font-size: 17px;
    font-weight: 400;
    color: #999;
    line-height: 24px;
  }
  .btn {
    margin-top: 34px;
    font-size: 17px;
    font-weight: 500;
    color: #576b95;
    line-height: 24px;
    letter-spacing: 1px;
    padding: 15px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.footer {
  padding: 0 10px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99;
  background: #fff;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .price {
    display: flex;
    align-items: flex-end;
    p {
      font-size: 15px;
      font-weight: 500;
      color: $primary-color;
      line-height: 15px;
    }
    .num {
      font-size: 20px;
      line-height: 19px;
    }
  }
  .submit {
    width: 116px;
    height: 45px;
    background: linear-gradient(90deg, #ffa466 0%, $primary-color 100%);
    border-radius: 24px;
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
/deep/ .van-tab--active {
  color: $primary-color;
}
/deep/ .van-tabs__line {
  background-color: $primary-color;
  height: 4px;
  width: 16px;
  border-radius: 2px;
  bottom: 20px;
}
.bottomPopup {
  /deep/ .van-popup {
    border-radius: 20px 20px 0 0;
    height: 70%;
  }
}
.centerPopup {
  /deep/ .van-popup {
    border-radius: 8px;
  }
}
.firstBottomPopup {
  /deep/ .van-popup {
    padding-bottom: 20px;
    border-radius: 20px 20px 0 0;
    height: auto;
  }
}
</style>
